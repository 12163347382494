.mainCalendar {
  &__preloaderWrapper {
    position: relative;
    min-height: 700px;
    width: 100%;

    .LocalPreloader {
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
    }

  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 40px 10px;
    background-color: white;
    border-radius: 0 0 6px 6px;

    &-item {
      position: relative;
      padding-left: 18px;
      padding-right: 12px;
      font: bold normal 14px/18px 'Proxima Nova';
      color: #2c2c2c;
      margin: 7px 10px;
      box-sizing: border-box;

      @media screen and (max-width: 1200px) {
        margin-right: 0;
        margin-left: 0;
      }

      @media (min-width: 993px) and (max-width: 1200px) {
        flex: 0 0 33.333%;
        max-width: 33.333%;
      }

      @media (min-width: 481px) and (max-width: 992px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media screen and (max-width: 480px) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &-indicator {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }



  &_student {
    .fc-header-toolbar {
      @media screen and (min-width: 768px) {
        top: 0;
      }
    }

    .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky>* {
      @media screen and (min-width: 769px) {
        top: 70px;
      }
    }
    .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky{
      @media screen and (max-width: 768px) {
        position: sticky;
        top: 3em;
        z-index: 100;
      }
    }


    .fc-timegrid-axis {
      .fc-timegrid-axis-frame:after {
        @media screen and (max-width: 768px) {
          padding: 10px;
          padding-left: 5px;
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #747474;
          content: 'Время';
        }
      }
    }

    .fc .fc-timegrid .fc-daygrid-body {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .fc .fc-col-header-cell-cushion {
      @media screen and (max-width: 768px) {
        visibility: hidden;
      }

    }

    .fc .fc-col-header-cell-cushion:after {
      @media screen and (max-width: 768px) {
        padding-left: 125px;
        visibility: visible;
        content: 'Мероприятие';
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        text-transform: none;
        text-align: right;
        letter-spacing: 0.02em;
        margin-left: -220px;
        color: #747474;
      }
    }

    .fc-header-toolbar {
      @media screen and (max-width: 768px) {
        position: relative !important;
        min-height: 0 !important;
        height: 50px;
        
        
      }
    }

    .fc .fc-toolbar-title {
      @media screen and (max-width: 768px) {
        position: relative !important;
        padding-left: 34px !important;
        white-space: nowrap;
      }
    }

    .fc .fc-toolbar-title:before {
      @media screen and (max-width: 768px) {
        position: absolute;
        top: 50%;
        left: -4px;
        transform: translateY(-50%);
        display: block;
        width: 25px;
        height: 25px;
        //background: url('/icons/calendar.svg')  no-repeat center;
        content: '';
      }
    }

    .fc-prev-button {
      margin-left: 0 !important;
    }
    .fc-col-header-cell {
      @media screen and (max-width: 1024px) {
        padding-right: 76px;
      }
    }
    .fc .fc-toolbar.fc-header-toolbar{
      @media screen and (max-width: 768px) {
        position: sticky !important;
        top: 0 !important;
        padding-right: 10px;
      }
    }
  }
}