@import url(assets/fonts/ProximaNova.css);
@import url(assets/fonts/BebasNeue.css);
@import url(assets/fonts/Inter.css);
.App {
  text-align: center;
}
.LayoutContainer {
  background: url('assets/images/dark-bg.png') #353535 no-repeat center;
  background-size: cover;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my_phone{
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  border: 1px solid #F3F3F3;
  border-radius: 5px;
  outline: none;
  padding-left: 8px;
  background-color: #fff;

  font-family: 'Proxima Nova';
  font-size: 14px;
}
.my_phone::placeholder {
  font: normal normal 14px/25px 'Proxima Nova';
  color: #c4c4c4;
  border: none !important;
}
/*Modal default styles*/
.rc-dialog {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 500px;
  min-height: 185px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
}
.rc-dialog-title {
  margin-top: 0;
  margin-bottom: 0;
  font: bold normal 16px/20px 'Proxima Nova';
  color: #2c2c2c;
  text-transform: uppercase;
  letter-spacing: .02em;
}
.rc-dialog-content {
  width: 100%;
}
.rc-dialog-wrap:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  z-index: 9999;
  content: '';
}
.rc-dialog .rc-dialog-close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  background-color: #f3f3f3;
  border-radius: 50%;
  border-color: transparent;
  cursor: pointer;
}
.rc-dialog .rc-dialog-close:after {
  display: block;
  background: url('assets/icons/cross.svg') no-repeat center !important;
  background-size: 32px;
  width: 24px;
  height: 24px;
  content: '';
}
.rc-dialog .rc-dialog-close:focus {
  outline: none;
}
.RadioGroup {
  margin-right: -4px;
  margin-left: -4px;
  box-sizing: border-box;
}
.ReactComponentModalDefault {
  padding: 0;
  display: block;
  min-width: 420px;
  min-height: auto;
}
.ReactComponentModalDefault .rc-dialog-header {
  padding: 20px 24px 0;
  font: bold normal 16px/20px 'Proxima Nova';
  color: #2c2c2c;
  letter-spacing: .02em;
  text-transform: uppercase;
}
.ReactComponentModalDefault .rc-dialog-body {
  position: relative;
  padding: 16px 24px 70px;
}
.ReactComponentModalDefault .rc-dialog-close {
  position: absolute;
  top: 16px;
  right: 24px;
}
.ReactComponentModalDefault .DialogButtons {
  padding: 8px 24px;
  bottom: 0;
  box-shadow: 4px 0 12px rgba(0, 0, 0, .1);
}

.ReactComponentModalDefault .DialogButtons .DialogButton {
  padding-left: 20px !important;
  padding-right: 20px  !important;
  border: 1px solid #EE1122 !important;
}
.ReactComponentModalDefault .DialogButtons .DialogButton_yes {
  background-color: #EE1122 !important;
  color: white !important;
}
.ReactComponentModalDefault .DialogButtons .DialogButton_no {
  background-color: transparent !important;
  color: #EE1122 !important;
}
.ReactComponentModalDefaultText {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-width: 320px;
}

.AddToFavoritesModal .DialogContent p.AddToFavoritesModalTitle {
  margin-top: 0;
  margin-bottom: 4px;
  font: normal normal 16px/18px 'Proxima Nova';
  color: #2c2c2c;
}
.AddToFavoritesModal .DialogContent input {
  margin-top: 4px;
  margin-bottom: 0;
  padding: 8px 12px;
  font: normal normal 16px/18px 'Proxima Nova';
}
.AddToFavoritesModal .DialogContent input::placeholder {
  border: 1px solid #F3F3F3;
  font: normal normal 16px/18px 'Proxima Nova';
  color: #747474;
}
.LinesNotSaved .DialogContent .ReactComponentModalDefaultText {
  max-width: 480px;
}
/*/////*/

.BodyMassEstimationCancelModal, .MedicalProfileCancelModal {
  min-height: 250px;
}
.MedicalProfileCancelModal {
  z-index: 9999;
}
.DialogButton_yes {
  background-color: transparent !important;
  border: 1px solid #990011 !important;
  color: #990011 !important;
}
.DialogButton_no {
  background-color: #990011 !important;
  color: white !important;
}
.DocumentViewModal {
  min-width: 250px;
  min-height: 250px;
}
.DocumentViewModal .rc-dialog-body {
  max-width: 400px;
}
.DocumentViewModal.DocumentViewModal_admin {
  min-width: 500px;
  min-height: 500px;
}
.DocumentViewModal.DocumentViewModal_admin .rc-dialog-content{
  width: 100%;
}
.my_phone:focus{
  border-color: #444951;
}
.RadioGroup {
  display: flex;
  flex-wrap: wrap;
}
.ReactTooltip {
  max-width: 120px;
  padding: 8px 4px !important;
  background: rgba(44, 44, 44, .85) !important;
  border-radius: 4px !important;
  white-space: pre-wrap;
  /*margin-left: 80px !important;*/
}

.ReactTooltip > p {
  margin-top: 0;
  margin-bottom: 0;
  font: normal normal 12px/16px 'Proxima Nova';
  color: white;
  letter-spacing: .02em;
}

/* Notifcation default styles*/

.rc-notification {
  padding: 0;
  position: absolute;
  top: 20px !important;
  right: 20px !important;
  left: inherit !important;
  display: flex;
  align-items: center;
  min-height: 48px;
  min-width: 32vw;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 10000;
    border-radius: 6px;
}

.rc-notification > div {
    width: 100%;
}

.rc-notification-notice-close {
  position: absolute;
  top: 8px;
  right: 15px;
  background-color: #00000030;
  border-radius: 50%;
  cursor: pointer;
}
.rc-notification-notice-close:focus {
  outline: none;
}
.rc-notification-notice-close:after {
  display: block;
  background: url('assets/icons/NotificationClose.svg') no-repeat center !important;
  background-size: 32px;
  width: 18px;
  height: 18px;
  content: '';
}
/*//////////////////////////*/
.react-datepicker__input-container{
  width: 100%;
}

.react-datepicker__input-container input{
  box-sizing: border-box;
  width: 100%;
  outline: none;
  height: 38px;
  line-height: 28px;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #F3F3F3;
  padding-left: 8px;

}

.react-datepicker__input-container input:focus{
  border-color: #C4C4C4;
}

.react-datepicker-wrapper{
  width: 100%;
}

@media print {
  /* body * {
    visibility: hidden;
  } */
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
  }
}

.grey-border {
  border: 1px solid rgb(204, 204, 204);
}

.background-color-white {
  background-color: #fff;
}

.ql-editor {
  min-height: 120px;
}

span[class$=indicatorSeparator] {
  display: none;
}

.select-box {
  font-family: 'Proxima Nova';
  font-size: 15px;
}

.select-box > div {
  border-color: #ccc;
}

.tab-active {
  background: #990011;
  border-radius: 20px;
  color: #FFFAED !important;
  border-bottom: none !important;
}

.student-form__group {
  position: relative;
}

.student-form__group .select-box{
  padding-right: 30px;
}
.student-form__group-clear {
  position: absolute;
  right: 5px;
  top: calc(50% - 10px);
}

.teacher-birthday {
  display: block;
  background-image: url('assets/images/Calendar.svg');
  background-size: 20px 20px;
  background-position-x: 98%;
  background-repeat: no-repeat;
  background-position-y: center;
  font-family: 'Proxima Nova';
  font-size: 15px;
  border: 1px solid #F3F3F3;
}

.sidebar {
  width: calc(100% - 250px) !important;
}
.sidebarShort {
  width: 720px !important;
}
.sidebar__inner .TabItemsBlock {
  margin-top: 10px;
  padding: 0px;
  position: relative;
  justify-content: center;
  border: none;
  align-items: center;
  box-shadow: none;
}
.AddStudentModal .sidebar__inner .sidebar__header {
  margin-bottom: 20px;
}
.AddStudentModal .sidebar__inner .sidebar__content .Header {
  display: none;
}
.SchoolGroupsPanelSidebar .sidebar__inner .TabItemsBlock {
  justify-content: flex-end;
  margin-top: 0;
}
.SchoolGroupsPanelSidebar .sidebar__inner .sidebar__header {
  margin-bottom: 0;
}
.SchoolGroupsPanelSidebar .sidebar__inner .sidebar__content #section-to-print .fc-view-container {
  padding-bottom: 0;
}
.sidebar__inner .TabItemsBlock .TabName:last-child{
  margin-right: 0;
}

.sidebar__header {
  position: relative;
  margin-bottom: 25px;
}

.sidebar__closer {
  position: absolute;
  right: 0;
}
.sidebar__closer .icon_name_close{
  background-image: url('./assets/images/cross.svg') !important;
  background-color: #F3F3F3;
  width: 24px;
  height: 24px;
  background-size: 10px;
  border-radius: 50%;
}

.sidebar__header-content {
  display: inline-block !important;

  font-family: 'Proxima Nova';
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #2C2C2C;
}

/* Date Range Picker Styles */

.react-daterange-picker {
  width: 100%;
}

.react-daterange-picker__wrapper {
  position: relative;
  padding: 8px 12px;
  border: 1px solid #F3F3F3 !important;
  border-radius: 5px;
}

.react-daterange-picker__inputGroup {
  min-width: initial !important;
  max-width: 90px !important;;
  text-align: center;
}

.react-daterange-picker__inputGroup__input,
.react-daterange-picker__inputGroup__leadingZero {
  font: normal normal 14px/25px 'Proxima Nova' !important;
  color: #2C2C2C;
}

.react-daterange-picker__inputGroup__input:focus {
  outline: none;
}

.react-daterange-picker__inputGroup__input::-webkit-input-placeholder {
  opacity: 1;
  font: normal normal 14px/25px 'Proxima Nova' !important;
  color: #2C2C2C;
}
.react-daterange-picker__inputGroup__input::-moz-placeholder {
  opacity: 1;
  font: normal normal 14px/25px 'Proxima Nova' !important;
  color: #2C2C2C;
}
.react-daterange-picker__inputGroup__input::-ms-input-placeholder {
  opacity: 1;
  font: normal normal 14px/25px 'Proxima Nova' !important;
  color: #2C2C2C;
}
.react-daterange-picker__inputGroup__input::placeholder {
  opacity: 1;
  font: normal normal 14px/25px 'Proxima Nova' !important;
  color: #2C2C2C;
}
.react-daterange-picker__button {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
.react-daterange-picker__button:focus {
  outline: none;
}
.react-daterange-picker__clear-button {
  display: none !important;
}
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #EE1122 !important;
}
.react-calendar__tile {
  font-family: 'Proxima Nova';
}
.react-calendar__tile--now {
  background: transparent !important;
}
.react-daterange-picker__calendar {
  margin-top: 16px;
  width: auto !important;
  top: 100% !important;
  right: auto !important;
  bottom: auto !important;
  left: 0 !important;
}
.react-calendar {
  min-width: 100% !important;
  width: 100% !important;
  max-width: none !important;
  border: 1px solid #F3F3F3 !important;
  border-radius: 5px;
}
.react-calendar__navigation__label__labelText {
  white-space: nowrap;
}
.calendarHeaderTr{
  position: absolute;
  display: flex;
  height: 25px;
  width: calc(100% - 4px);
  top: 158px;
  z-index: 1;
  background-color: #fff;
  align-items: flex-end;
  box-shadow: 0px 9px 10px -7px rgba(0,0,0,0.1)
}
.calendarHeaderTd{
  margin: 4px 0;
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 14px;
  color: #747474;
}
.calendarHeaderTdNotStudent:first-child, .calendarHeaderTd:first-child{
  margin-left: 110px;
  min-width: 20%;
}
.calendarHeaderTdNotStudent:nth-child(2), .calendarHeaderTd:nth-child(2){
  min-width: 25%;
  padding-left: 30px;
}
.calendarHeaderTdNotStudent:nth-child(3), .calendarHeaderTd:nth-child(3){
  min-width: 15%;
  padding-left: 30px;
}
.calendarHeaderTdNotStudent:nth-child(4), .calendarHeaderTd:nth-child(4){
  min-width: 20%;
}

.calendarHeaderTrNotStudent {
  display: flex;
  height: 25px;
  background-color: #fff;
  align-items: flex-end;
  box-shadow: 0 9px 10px -7px rgba(0,0,0,0.1);
}
.calendarHeaderTrStudent {
  display: flex;
  align-items: flex-end;
  height: 25px;
  background-color: #fff;
  box-shadow: 0 9px 10px -7px rgba(0,0,0,0.1);
  z-index: 1;
}
.calendarHeaderTdNotStudent{
  margin: 4px 0;
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 14px;
  color: #747474;
}
.event-right-wrapper-custom {
  position: absolute;
  right: 0;
}
.event-left-wrapper-custom {
  padding-right: 12px;
}

.event__estimation-link-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
}
.event__estimation-link {
  display: block;
  margin: 0;
  font: normal normal 14px/16px 'Proxima Nova';
  color: white;
  text-decoration: underline;
  cursor: pointer;
  transition: text-decoration .33s;
}
.event__estimation-link:focus {
  outline: none;
}
.event__estimation-link:hover {
  text-decoration: none;
}

.ExportCsv {
  width: 18px;
  height: 18px;
  z-index: 1;
}

.CalendarButtonLabel {
  margin-left: 10px;
  font: bold normal 20px/36px 'Bebas Neue';
  color: #2C2C2C;
  text-transform: uppercase;
}
/* Calendar of events on student dashboard */
.GameEstimationInput:focus {
  border-color: #282c34!important;
}
.StudentDashboardCalendar .CalendarFooter {
  padding: 18px 0;
  margin: 0;
}
.StudentDashboardCalendar .CalendarFooterItem {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  margin-top: 6px;
}
.StudentLessonsCalendar {
  position: relative;
}
.StudentLessonsCalendar .CalendarFooter{
  margin-right: 0;
  margin-left: 0;
}
.StudentLessonsCalendar_personal-panel,
.StudentLessonsCalendar_franchisee {
  position: relative;
}
.StudentLessonsCalendar_admin {
  position: relative;
}
.TeacherLessonsCalendar .PrintSectionContainer_admin {
  padding-left: 24px;
}

.rbc-calendar{
  background: #fff;
}
.rbc-date-cell {
  z-index: 1 !important;
}
.rbc-event {
  position: absolute !important;
  top: 0 !important;
  width: 100% !important;
  max-width: calc(100% / 7 - 2px) !important;
  min-height: 76px !important;
  border-radius: 0!important;
  outline: none;
}
.rbc-event .rbc-date-cell {
  color: white !important;
}
.rbc-today {
  background-color: #fffaed!important;
}
.rbc-show-more {
  color: #282c34;
}
.rbc-time-content {
  display: none!important;
}
.rbc-label rbc-time-header-gutter{
  display: none!important;
}
.rbc-time-header{
  height: 100%;
}
.rbc-toolbar {
  position: relative;
}
.rbc-toolbar .rbc-btn-group button {
  border: 0;
  cursor: pointer;
  font: 600 normal 16px/20px 'Proxima Nova';
}

.rbc-toolbar .rbc-btn-group button:nth-child(2) {
  position: absolute;
  top: 0;
  left: 20px;
}
.rbc-toolbar .rbc-btn-group button:last-child {
  position: absolute;
  top: 0;
  right: 20px;
}
.rbc-toolbar-label{
  text-transform: capitalize;
  font: 600 normal 16px/20px 'Proxima Nova';
}
.rbc-row-segment{
  margin-top: 15px;
}
.rbc-row-segment .rbc-event-content {
  position: absolute;
  top: 0;
  right: 4px;
  z-index: 2;
  background: inherit !important;
}
.rbc-month-view  .rbc-row-segment{
  margin-top: 2px;
}
.rbc-time-header-gutter{
  display: none;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  display: none;
}
/*нужно вынести в {styles}*/
.css-vzhnag-control {
  border-color: #C4C4C4 !important;
  box-shadow: none !important;
}
.css-y90rpz-control {
  border-color: #F3F3F3 !important;
}
.css-xo7z33-option {
  background-color: #B6B6B6  !important;
  color: black  !important;
}
.css-dpec0i-option {
  background-color: #fff !important;
  color: black  !important;
}

/* TinyMCE styles */

.tox-tinymce {
  border: 1px solid rgba(196, 196, 196, .6) !important;
  border-radius: 5px !important;
}


.tox .tox-toolbar__primary {
  background: transparent !important;
}
/* Date picker styles */

.react-datepicker-popper {
  z-index: 2 !important;
}
.react-datepicker {
  border: 1px solid #f3f3f3!important;
  border-radius: 5px !important;
  overflow: hidden !important;
}
.react-datepicker__day:hover {
  background-color: #e6e6e6 !important;
}
.react-datepicker__day--selected {
  background-color: #e12!important;
}
.react-datepicker__day--keyboard-selected {
  background-color: #e12!important;
}
.react-datepicker__day--keyboard-selected:focus {
  background-color: #e12!important;
  color: white !important;
  outline: none;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: #e12!important;
  color: white !important;
}
.react-datepicker__triangle {
  display: none !important;
}
.react-datepicker__header {
  background-color: white!important;
  border-bottom: 0!important;
}
.react-datepicker__year-read-view--down-arrow {
  top: 5px!important;
}

.estimate-report-arrows{
  line-height: 48px;
  cursor: pointer;
}

.estimate-report-arrows path{
  fill-opacity: 0.8;
}

.chart-last-param {
  position: relative;
  top: -30px;
}


.MedicalProfileAddButton{
  margin-left: auto;
  align-self: center;
}

.mt10{
  margin-top: 10px;
}

.PTReport-first-line{
  position: sticky;
  left: 0px;
  padding-left: 34px;
  background: rgb(248, 249, 249);
  z-index: 2;
  width: 100%;
  min-width: 300px;
  box-sizing: border-box;
  border-right: 1px solid rgb(221, 218, 212);
  border-bottom: 1px solid rgba(192, 192, 192, 0.314);
}

.PTReport-first-TH{
  text-align: left !important;
}
.PTReport-first-TR{
  text-align: left !important;
  display: inline-block !important;
}
.PTReport-row-border-right{
  border-right: 1px solid #dddad4;
}
.PTReport-row-border-bottom{
  border-bottom: 1px solid #dddad4;
}

.PTReport-row-special-bg {
  background: linear-gradient(0deg, #F4EEE280, #F4EEE280);
}
.PTReport-column-fix {
  min-width: 85px;
}
table.js-sort-table.js-sort-desc thead tr > .js-sort-active.PTReport-sort-arrow-fixer:not(.js-sort-none)::after,
table.js-sort-table.js-sort-asc thead tr > .js-sort-active.PTReport-sort-arrow-fixer:not(.js-sort-none)::after{
  position: absolute;
  top: 18px;
  right: 5px;
}
table.js-sort-table.js-sort-desc thead tr > .js-sort-active:not(.js-sort-none)::after, table.js-sort-table.js-sort-asc thead tr > .js-sort-active:not(.js-sort-none)::after{
  position: absolute;
  top: 50%;
  right: auto;
  transform: translateY(-50%);
  color: #747474
}

.PTReport-hide-column{
  display: none;
}

/*table#teamPTReport th {*/
/*min-width: 85px;*/
/*}*/

/*table#teamPTReport th:first-of-type {*/
/*min-width: 300px;*/
/*}*/

#teamPTReport thead th:not(.HighlightColumn), #teamPTReport tbody th:not(.HighlightColumn) {
  position: relative;
  min-width: 100px;
}
#teamPTReport thead th:not(.HighlightColumn):last-child {
  min-width: 200px;
}

.student-docs-tab {
  padding: 30px;
}

.student-docs-tab .TabNamesItems {
  justify-content: flex-start;
}
.student-docs-tab .TabName{
  color:#000;
}

.text-left {
  text-align: left !important;
}

.TopPlaceholder_with-desc + .Wrapper .ContentPlaceholder {
  padding-top: 36px;
}

@media screen and (min-width:1800px) {
  .react-kanban-board > div {
    width: 100%;
  }
}

@media (min-width:1201px) and (max-width: 1600px) {
  .rbc-toolbar > .rbc-btn-group{
    margin-top: 10px;
  }
  .rbc-toolbar-label{
    width: 100%;
    margin-top: 5px;
  }
}

@media screen and (max-width: 1200px) {
  .calendarHeaderTr{
    top: 161px;
  }
}

@media screen and (max-width: 1024px) {
  .StudentLessonsCalendar_personal-panel .HeadlineTop {
    padding-bottom: 16px;
  }
  .AdminTeamAppLessonsPanel .SchoolLessonsPanel .HeadlineTop {
    padding-bottom: 50px;
  }
  .OptionsList {
    background: url('assets/images/dark-bg.png') #353535 no-repeat center;
    background-size: cover;
  }
}

@media (min-width:991px) and (max-width: 1024px) {
  .TeacherLessonsCalendar .PrintSectionContainer_admin {
    top: 192px;
  }
}
@media screen and (max-width: 991px) {
  .PTReport-first-line {
    position: relative !important;
  }
  .calendarHeaderTr{
    top: 203px;
  }
  .event__estimation-link {
    width: 100%;
    text-align: center;
    white-space: pre-line;
  }
  #teamPTReport thead th:not(.HighlightColumn), #teamPTReport tbody th:not(.HighlightColumn) {
    min-width: 150px;
  }
  #teamPTReport thead th:not(.HighlightColumn):last-child {
    min-width: 300px;
  }
  #teamPTReport thead th:not(.HighlightColumn):first-child, #teamPTReport tbody th:not(.HighlightColumn):first-child {
    text-align: left;
  }
}

@media(max-width: 900px) {
  .calendarHeaderTdNotStudent:nth-child(2), .calendarHeaderTd:nth-child(2){
    padding-left: 40px;
  }
}

@media screen and (max-width: 790px) {
  .sidebar__inner .TabItemsBlock {
    margin-top: 0px;
  }

  .Links {
    width: 100% !important;
  }

  .PlayersSelect {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .sidebar_theme_alfa-on-white .sidebar__footer:after {
    display: none;
  }
  .calendarHeaderTrNotStudent {
    display: none;
  }
  .rc-notification {
    left: 210px !important;
    transform: none !important;
    width: 100%;
    max-width: 300px;
  }
  .BodyMassIndexEstimationModal_view .sidebar__content {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .BodyMassIndexEstimationModal_view .sidebar__content .Heading {
    top: -8px;
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .sidebar_theme_alfa-on-white .sidebar__footer:after {
    display: none;
  }
  .calendarHeaderTrNotStudent {
    display: none;
  }
  .rc-notification {
    left: 210px !important;
    transform: none !important;
    width: 100%;
    max-width: 300px;
  }
  .BodyMassIndexEstimationModal_view .sidebar__content {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .BodyMassIndexEstimationModal_view .sidebar__content .Heading {
    position: sticky;
    top: 0;
    padding-top: 0;
    max-width: calc(100% - 40px);
  }
  .BodyMassIndexEstimationModal_view .sidebar__header {
    position: absolute;
    min-height: 60px;
  }
}

@media screen and (max-width: 615px) {
  .calendarHeaderTdNotStudent:first-child, .calendarHeaderTd:first-child{
    min-width: 24%;
  }
  .calendarHeaderTdNotStudent:nth-child(2), .calendarHeaderTd:nth-child(2){
    padding-left: 0px;
  }
}
@media screen and (max-width: 576px) {
  .AdminTeamAppLessonsPanel .SchoolLessonsPanel .HeadlineTop {
    padding-bottom: 70px;
  }
  .StudentLessonsCalendar_admin .HeadlineTop {
    padding-bottom: 46px;
  }
  .rc-dialog {
    min-width: 400px;
    max-width: 400px;
    text-align: center;
  }
}

@media (min-width: 480px) and (max-width: 600px) {
  .calendarHeaderTdNotStudent:first-child, .calendarHeaderTd:first-child{
    margin-left: 90px !important;
    min-width: 30%;
  }
}

@media (min-width: 480px) and (max-width: 577px) {
  .sidebar {
    width: calc(100% - 195px) !important;
  }
  .StudentLessonsCalendar_personal-panel .HeadlineTop {
    padding-bottom: 32px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 100% !important;
  }
  .calendarHeaderTdNotStudent:first-child, .calendarHeaderTd:first-child{
    min-width: 27%;
  }
  .TeacherLessonsCalendar .PrintSectionContainer_admin {
    top: 340px;
    width: 100%;
  }
  .StudentLessonsCalendar_personal-panel .HeadlineTop {
    padding-bottom: 28px;
  }
  .TeacherLessonsCalendar .PrintSectionContainer_admin {
    padding-left: 0;
  }
  .rc-notification {
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100%;
    max-width: 300px;
    top: 60px !important;
  }
  .rc-dialog {
    min-width: 360px;
    max-width: 360px;
  }
  .DocumentViewModal .rc-dialog-close {
    top: 10px;
    right: 10px;
  }
  .ReactComponentModalDefault .rc-dialog-title {
    font-size: 12px;  
  }
  .ReactComponentModalDefault .rc-dialog-body {
    padding-bottom: 104px;
  }
}

@media (min-width: 376px) and (max-width:480px) {
  .AdminTeamAppLessonsPanel .SchoolLessonsPanel #section-to-print .fc-toolbar.fc-header-toolbar {
    top: 82px;
  }
  .CalendarTabs + .Tab .ContentPlaceholder .SchoolLessonsPanel_teacher .HeadlineTop_teacher {
    padding-bottom: 58px;
  }
}

@media (max-width: 400px) {
  .calendarHeaderTdNotStudent:first-child, .calendarHeaderTd:first-child {
    min-width: 23%;
  }
}
@media (max-width: 375px) {
  .rc-dialog {
    min-width: 300px;
    max-width: 300px;
  }
}
@media (max-width: 360px) {
  .StudentLessonsCalendar_personal-panel .HeadlineTop {
    padding-bottom: 36px;
  }
  .rc-notification {
    max-width: 200px !important;
  }
  .react-daterange-picker__calendar {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .ReactComponentModalDefault .rc-dialog-title {
    padding-right: 28px;
  }
}

@media (max-width: 340px){
  .calendarHeaderTdNotStudent:first-child, .calendarHeaderTd:first-child{
    margin-left: 60px !important;
    min-width: 31%;
  }
}
.CalendarTabs{
  position: absolute;
  left: 50%;
}
.CalendarTabs .TabName{
  color: #282c34;
}

@media (max-width: 1280px){
  .CalendarTabs .TabName{
    display: none!important;
  }
}

.add-when-print {
    display: none;
}

@media print { /* print styles go here */
    .show-when-print{
        opacity: 1;
        width: 40px;
        height: 40px;
    }
    .hide-when-print{
        opacity: 0;
        width: 40px;
        height: 40px;
    }
    .add-when-print{
        display: block;
    }
}

.student__line {
    text-decoration: none;
}

.student-lines-button{
    position: relative;
    top: -7px;
    left: 10px;
}


/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
    position: relative;
    left: 15px;
    top: -1px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #474d53;
    color: #474d53;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
    display: inline-block;
}

.dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #474d53;
    color: #474d53;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #474d53;
    color: #474d53;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: #474d53;
    }
    50%,
    100% {
        background-color: #ccc;
    }
}

.filter-by-period {
    position: relative !important;
    z-index: 100 !important;
}
.crop-input{
    margin-bottom: 15px;
}

.CropDialogButton{
    padding: 20px 40px !important;
    line-height: 0 !important;
}


.CropDialogButton.DialogButton_yes{
    width: 120px !important;
}

.CropDialogButton .spin {
    position: absolute;
    right: 8px;
    top: 10px;
}

.light-button {
    background-color: #fff !important;
    border: 1px solid #ed1322 !important;
    color: #ed1322 !important;
}

.players-table .rc-tabs-tab {
    color: #990011 !important;
}
.players-table .rc-tabs-tab-active {
    color: #fff !important;
}
.players-table .rc-tabs-nav-list {
    padding-left: 0 !important;
}


.button-link{
    font: 600 normal 14px/40px 'Proxima Nova' !important;
    color: #990011 !important;
    text-decoration: underline;
}

.disable-text {
    color: rgba(196, 196, 196, .6) !important;
}

.read-only-lines > div:not(.LinesAvailablePlayersBlock) .rc-tabs-nav-list .rc-tabs-tab:nth-child(2) {
    display: none;
}

.LinesAvailablePlayersBlock .rc-tabs-nav-list .rc-tabs-tab:nth-child(2) {
    display: inline-block !important;
}

.read-only-lines .LinesTabLineContainer button {
    display: none;
}
.read-only-lines .LinesButtonsBlock {
    display: none;
}
.read-only-lines .read-only-hide{
    display: none;
}

.rc-tabs-dropdown {
    position: absolute;
    background: #fefefe;
    border: 1px solid black;
    max-height: 200px;
    overflow: auto;
}
.rc-tabs-dropdown-hidden {
    display: none;
}
.rc-tabs-dropdown-menu {
    margin: 0;
    padding: 0;
    list-style: none;
}
.rc-tabs-dropdown-menu-item {
    padding: 4px 8px;
    cursor: pointer;
}
.rc-tabs-dropdown-menu-item-active {
    background: rgba(0,0,0, 0.2);
}
.rc-tabs-dropdown-menu-item-selected {
    background: red;
}
.rc-tabs-dropdown-menu-item-disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.rc-tabs-nav-more {
    background: #fff;
    border: none;
    font: normal normal 11px/20px 'Proxima Nova' !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    width: 50px;
    box-shadow: -4px 0px 4px -1px #aaaaaa;
    color: #990011;
    cursor: pointer;
}
#psychotestTooltip {
  position: absolute !important;
  top: 60% !important;
  left: 60% !important;
  transform: translate(-50%, -50%) !important;
}  