@font-face {
	font-family: 'Proxima Nova Cn Lt';
	src: url('ProximaNova/ProximaNovaCond-LightIt.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova/ProximaNova-LightIt.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Rg';
	src: url('ProximaNova/ProximaNovaCond-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('ProximaNova/ProximaNova-Extrabld.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Bl';
	src: url('ProximaNova/ProximaNovaCond-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Th';
	src: url('ProximaNova/ProximaNovaCond-ExtrabldIt.woff') format('woff');
	font-weight: 800;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('ProximaNova/ProximaNova-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova/ProximaNova-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Th';
	src: url('ProximaNova/ProximaNovaExCn-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Lt';
	src: url('ProximaNova/ProximaNovaCond-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Lt';
	src: url('ProximaNova/ProximaNovaExCn-SemiboldIt.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Lt';
	src: url('ProximaNova/ProximaNovaExCn-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('ProximaNova/ProximaNova-ThinIt.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Th';
	src: url('ProximaNova/ProximaNovaCond-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Rg';
	src: url('ProximaNova/ProximaNovaCond-RegularIt.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Th';
	src: url('ProximaNova/ProximaNovaExCn-ThinIt.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova/ProximaNova-SemiboldIt.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('ProximaNova/ProximaNova-RegularIt.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Th';
	src: url('ProximaNova/ProximaNovaCond-Extrabld.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Th';
	src: url('ProximaNova/ProximaNovaExCn-ExtrabldIt.woff') format('woff');
	font-weight: 800;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Bl';
	src: url('ProximaNova/ProximaNovaExCn-BlackIt.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Lt';
	src: url('ProximaNova/ProximaNovaExCn-LightIt.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Th';
	src: url('ProximaNova/ProximaNovaCond-ThinIt.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Lt';
	src: url('ProximaNova/ProximaNovaExCn-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Rg';
	src: url('ProximaNova/ProximaNovaCond-BoldIt.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Th';
	src: url('ProximaNova/ProximaNovaExCn-Extrabld.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Rg';
	src: url('ProximaNova/ProximaNovaExCn-BoldIt.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Bl';
	src: url('ProximaNova/ProximaNova-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('ProximaNova/ProximaNova-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Rg';
	src: url('ProximaNova/ProximaNovaExCn-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('ProximaNova/ProximaNovaT-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('ProximaNova/ProximaNova-BoldIt.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Rg';
	src: url('ProximaNova/ProximaNovaCond-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Bl';
	src: url('ProximaNova/ProximaNovaExCn-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Rg';
	src: url('ProximaNova/ProximaNovaExCn-RegularIt.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Lt';
	src: url('ProximaNova/ProximaNovaCond-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('ProximaNova/ProximaNova-ExtrabldIt.woff') format('woff');
	font-weight: 800;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Bl';
	src: url('ProximaNova/ProximaNovaCond-BlackIt.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova ExCn Rg';
	src: url('ProximaNova/ProximaNovaExCn-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova/ProximaNova-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn Lt';
	src: url('ProximaNova/ProximaNovaCond-SemiboldIt.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Bl';
	src: url('ProximaNova/ProximaNova-BlackIt.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}
