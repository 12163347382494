@font-face {
	font-family: 'Bebas Neue';
	src: url('BebasNeue/BebasNeueRegular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('BebasNeue/BebasNeueBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('BebasNeue/BebasNeueLight.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('BebasNeue/BebasNeue-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bebas Neue Book';
	src: url('BebasNeue/BebasNeueBook.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
